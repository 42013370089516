<template>
  <div justify="center">
    <v-btn outlined width="100px" @click.stop="openForm">
      <span>削除</span>
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="color: #ff6e40">削除</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div v-for="msg of apierror.messages" :key="msg">
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <div style="line-height: 28px; font-size: 15px">
              <span style="font-size: 16px"><strong>{{ address.addr_sei }} {{ address.addr_mei }}</strong></span><br />
              <span>{{ address.addr_zip }}</span><br />
              <span>{{ address.addr_addr1 }}</span><br />
              <span v-if="address.addr_addr2 !== null">{{ address.addr_addr2 }}</span><br v-if="address.addr_addr2 !== null" />
              <span>{{ address.addr_telno }}</span><br />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeForm()">
            <span>取消</span>
          </v-btn>
          <v-btn color="#ff6e40" depressed :loading="submitStatus" @click="deleteItem(address)">
            <span style="color: #fff">削除</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

export default {
  props: ['id'],
  data: () => ({
    submitStatus: false,
    dialog: false,
    address: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),

  computed: {
    ...mapState('addressStore', ['selectedAddress']),
  },

  methods: {
    ...mapActions('addressStore', ['deleteAddress', 'loadAddress']),
    ...mapMutations('app', ['setOverlayStatus']),

    deleteItem(address) {
      this.submitStatus = true
      this.deleteAddress(address.addr_id)
        .then(() => {
          this.submitStatus = false
          this.$emit('deleted')
          this.dialog = false
        })
        .catch(error => {
          this.submitStatus = false
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
    },

    openForm() {
      this.apierror = []
      this.setOverlayStatus(true)
      this.loadAddress(this.id).then(() => {
        this.address = {
          ...this.selectedAddress,
        }
        this.setOverlayStatus(false)
        this.dialog = true
      })
    },
    closeForm() {
      this.$emit('cancel')
      this.dialog = false
      this.address = {
      }
    },
  },
}
</script>

<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner2 pt-6">
      <v-card class="px-6 py-6 my-12">
        <div class="mb-6" style="font-size: 20px; font-weight: bold">
          <span>お届け先住所</span>
        </div>
        <div v-if="addressList.length == 0" width="100%">
          <span class="d-flex justify-center my-12" style="font-size: 16px; font-weight: bold"
            >新しいお届け先を追加してください</span
          >
        </div>
        <v-row v-for="(address, index) in displayLists" :key="index" class="px-3">
          <v-col cols="12" sm="8">
            <div style="line-height: 28px; font-size: 15px">
              <span style="font-size: 16px"
                ><strong>{{ address.addr_sei }} {{ address.addr_mei }}</strong>
                <strong v-if="address.addr_default === 1" style="color: #ff6e40; font-size: 14px">
                  ( 自宅 )</strong
                ></span
              ><br />
              <span>{{ address.addr_zip }}</span
              ><br />
              <span>{{ address.addr_addr1 }}</span
              ><br />
              <span v-if="address.addr_addr2 !== null">{{ address.addr_addr2 }}</span
              ><br v-if="address.addr_addr2 !== null" />
              <span v-if="address.addr_company !== null">{{ address.addr_company }}</span
              ><br v-if="address.addr_company !== null" />
              <span v-if="address.addr_department !== null">{{ address.addr_department }}</span
              ><br v-if="address.addr_department !== null" />
              <span>{{ address.addr_telno }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div v-if="address.addr_default !== 1">
              <div class="d-flex justify-end">
                <v-btn depressed color="#ff6e40" width="100px" class="mb-4" @click="editItem(address)">
                  <span style="color: #fff">変更</span>
                </v-btn>
              </div>
              <div class="d-flex justify-end">
                <DeleteForm :id="address.addr_id" @deleted="refreshList" />
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="mt-10">
          <v-btn depressed style="width: 180px; background-color: #ff6e40" @click="addresscreate">
            <span style="color: #fff">新しいお届け先を追加</span>
          </v-btn>
        </div>
        <v-pagination
          v-if="displayLists.length != 0"
          v-model="page"
          class="my-6"
          :length="length"
          :total-visible="7"
          color="#ff6e40"
          @input="pageChange"
        ></v-pagination>
        <div class="d-flex justify-end mt-12">
          <v-btn depressed color="#ff6e40" width="180px" class="px-12" @click="back">
            <span style="color: #fff">ホームに戻る</span>
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import DeleteForm from '../cart/components/DeleteAddress.vue'

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    id: '',
    List: [],
    displayLists: [],
    page: 1,
    length: 0,
    pageSize: 3,
  }),
  computed: {
    ...mapState('addressStore', ['addressList']),
  },
  watch: {
    addressList() {
      const pages = Math.ceil(this.addressList.length / this.pageSize)
      if (this.page > pages) {
        this.page = pages
      }
      this.displayLists = this.addressList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
      this.length = Math.ceil(this.addressList.length / this.pageSize)
    },
  },
  created() {
    this.refreshList()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('addressStore', ['loadAddressList']),

    refreshList() {
      this.setOverlayStatus(true)
      this.loadAddressList().then(() => {
        this.setOverlayStatus(false)
      })
    },

    pageChange(pageNumber) {
      this.displayLists = this.addressList.slice(this.pageSize * (pageNumber - 1), this.pageSize * pageNumber)
      window.scrollTo(0, 0)
    },
    editItem(item) {
      this.$router.push({
        name: 'addressedit',
        params: {
          id: item.addr_id,
          query: this.$route.query,
        },
      })
    },
    addresscreate() {
      this.$router.push('/addresscreate')
    },
    back() {
      this.$router.push('/home')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
